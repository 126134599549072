'use strict';

import * as CONST from "../app/constants";
const React = require('react');
const ReactDOM = require('react-dom');

const { Card, CardBody, Container, Row } = require('reactstrap');
const { Link } = require('react-router-dom');

class App extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
            <>
                <CONST.Header/>
                <Container fluid style={{backgroundImage: "linear-gradient(to top, #00000070, #ffffff70 52.52%), url('/img/home/books.jpg')"}}>
                     {this.props.content}
                     <br/>
                     <br/>
                     <label className="p-1 border-warning bg-light"> <img src="/app/qr.png" style={{width: "50px"}}/> </label>
                     <label className="p-2 border-warning bg-light"> https://school.stacktrace.in/app/school.apk </label>
                     <br/>
                </Container>
            </>
		)
	}
}

export default App;